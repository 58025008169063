.noChatWrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: center;
  align-items: center;
  padding: 20px 10%;
}

.noChatImg{
  width: 40%;
  margin-bottom: 100px;
}

.noChatGooglePlay{
  cursor: pointer !important;
  color: #557CF2;
  text-decoration: underline !important;
}

.noChatInsta{
  cursor: pointer !important;
  color: #557CF2;
  text-decoration: underline !important;
}

.noChatBar{
  border: 0.5px solid #eee;
  width: 100%;
  margin-bottom: 10px;
}