.title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.lastUpdate {
  font-size: 0.8rem;
  color: #c2c2c2;
  margin-bottom: 1rem;
}

.introduction {
  font-size: 1rem;
  color: #999;
  line-height: 1.5;
  margin-bottom: 1rem;
  flex-direction: row;
}

.attention {
  font-size: 0.9rem;
  color: #000;
  line-height: 1.5;
  margin-bottom: 1rem;
  flex-direction: row;
}

.subTitle {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.description {
  font-size: 1rem;
  color: #444;
  line-height: 1.5;
  margin-bottom: 1rem;
  flex-direction: row;
}

.points {
  font-size: 1rem;
  color: #444;
  line-height: 1.5;
  margin-bottom: 1rem;
  flex-direction: row;
  margin-left: 20px;
}
