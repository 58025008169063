.messageBoxWrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  align-items: center;
  padding: 20px 5% 0px 5%;
}

.selectedMessageHeader{
  height: 72px;
  box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 10%);
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0px 20px;
  justify-content: space-between;
}

.menuRegisto2 {
  margin: 0px -0.75rem 0 -0.7rem;
  /* background-color: #557CF2; */
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  /* position: absolute;
  right: 960px;
  top: 205px; */
  width: 39px;
}

.messageSendBallon{
  display: flex;
  align-self: flex-end;
  padding: 10px;
  background-color: #557CF2;
  border-radius: 10px;
  /* border-bottom-right-radius: 0; */
  color: #fff;
  margin-bottom: 15px;
  flex-direction: column;
  max-width: 70%;
  position: relative;
}

.messageReceivedBallon{
  display: flex;
  align-self: flex-start;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  /* border-bottom-left-radius: 0; */
  color: #222222;
  margin-bottom: 15px;
  flex-direction: column;
  max-width: 70%;
  position: relative;
}

.messageSendHour{
  align-self: flex-end;
}

.messageReceivedHour{
  align-self: flex-end;
}

.selectedMessageInputWrapper{
  height: 70px;
  box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 10%);
  padding: 10px 25px;
  display: flex;
  max-height: 200px;
  justify-content: space-between;
  align-items: center;
}

.selectedMessageInput{
  width: 85%;
  border-radius: 10px;
  border: none;
  background-color: #f5f5f5;
  padding: 5px 20px;
  resize: none;
  
}

.buttonSendMsg{
  width: 5%;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 57px;
}


.messageSendBallon:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #557CF2;
  border-right: 15px solid transparent;
  border-top: 10px solid #557CF2;
  border-bottom: 15px solid transparent;
  right: -16px;
  top: 0px;
}


/* speech bubble 14 */

.messageReceivedBallon:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 15px solid #f5f5f5;
  border-top: 10px solid #f5f5f5;
  border-bottom: 15px solid transparent;
  left: -16px;
  top: 0px;
}

.separador{
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  color: grey;
}

.separador:after {
  background-color: #bbbbbb;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 20%;
  margin: 0 20px;
}

.separador:before {
  background-color: #bbbbbb;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 20%;
  margin: 0 20px;
}

.emoji_body.popover-body{
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

/* MOBILE */

  .selectedMessageWrapperMobile{
    height: 96vh;
    width: 100%;
    box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 10%);
    position: absolute;
    top: 55px;
    right: 0;
    overflow: hidden;
  }

.selectedMessageHeaderMobile{
  height: 7%;
  box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 10%);
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0px 10px;
  justify-content: space-between;
}

.avatarHeaderChatMobile{
  height: 45px;
  width: 45px;
  background-size: cover;
  border-radius: 25px;
}

.selectedMessageMobile{
  height: 83%;
  overflow: auto;
  padding: 10px 15px;
}

@media (max-width:767px) {
  .messageSendBallon{
    max-width: 90% !important;
  }
  .messageReceivedBallon{
    max-width: 90% !important;
  }
}

