.input{
  border-bottom: 2px solid #444;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 1px;
  outline: 0;
  box-shadow: 0 0 0 0;
  width: 100%;
  background-color: var(--cafofo-background-color) 
}

.iconEye{
  margin-top: 13px;
}

.errorAlert{
  color: #FF6B6B;
}

.logoWrapper{
  width: 75%;
}

.loginWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 50%;
}

.backgoundImg{
  display: none;
}

.backgoundImg1360{
  display: none;
}

.formWrapper{
  width: 75%;
  margin-top: 20px;
}

.loginScreenWrapper{
  width: 100%;
  height: 90vh;
  display: flex;
}

.downloadGoogle{
  width: 140px;
  height: 45px;
  z-index: 10;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 40px;
  right: 30px;
}

.forgot-pass{
  align-self: center;
  margin-top: 10px;
  color: var(--cafofo-color);
  text-decoration: underline;
  cursor: pointer;
  text-decoration-color: #444;
}

@media (max-width:767px) {
  .logoWrapper{
    width: 100%;
  }
  .formWrapper{
    width: 100%;
  }
  .loginWrapper{
    width: 100% !important;
  }
  .downloadGoogle{
    display: none;
  }
}

@media (max-width:820px) {
  .logoWrapper{
    width: 100%;
  }
  .formWrapper{
    width: 100%;
  }
  .loginWrapper{
    width: 100% !important;
  }
  .downloadGoogle{
    display: none;
  }
}

@media (min-width:1300px) {
  .backgoundImg1360{
    height: 100%;
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
  }
}

@media (min-width:1900px) {
  .backgoundImg1360{
    height: 100%;
    display: block;
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0;
  }
}