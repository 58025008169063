.ContainerDetail{
  padding: 0px 9%;
}

.options-wrapper{
  display: flex;
}

.options-wrapper :first-child{
  margin-right: 5px;
}

.photo-video-selector{
  display: flex;
  flex-direction: row;
  position: absolute;
  margin-top: 5px;
  gap: 5px;
}

.photo-video-selector button{
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  width: 70px;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
}

.photo-video-selector button:hover{
  background-color: rgba(0, 0, 0, 0.6);
}

.youtube-wrapper{
  width: 800px;
  height: 500px;
  border-radius: 10px;
}

.mobileFooter{
  display: none;
}

.vacancyNameDetail{
  color: #222222 !important;
  font-weight: 400 !important;
  font-size: 26px !important;
}

.leftColumn{
  width: 50%;
}

.vacancyLocationDetail{
  color: #717171;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.vacancyLocationDetail svg{
  margin-right: 5px;
  margin-bottom: 1px;
}

.vacancyLocationWrapper{
  display: flex;
  justify-content: space-between;
}

.btnFavorite{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--cafofo-background-color);
}

.saveIcon{
  font-size: 18px;
  margin-right: 5px;
}

.btnFavorite span{
  color: #222222;
  text-decoration: underline;
}

.imgWrapperDetail{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.imgWrapperDetail img{
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50px;
}

.descriptionWrapper{
  border-top: 1px solid #ddd;
  word-break: break-word;
}

.bottomWrapper{
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.descriptionWrapper span{
  font-size: 20px;
  color: #222222;
  font-weight: 500;
}

.description{
  margin-top: 10px;
  line-height: 1.7;
  padding: 0px 10px;
}

.benefitsWrapper{
  border-top: 1px solid #ddd;
  margin-top: 40px;
}

.benefits{
  padding: 0px 10px;
}

.benefitsWrapper span{
  font-size: 20px;
  color: #222222;
  font-weight: 500;
}

.wppWrapper{
  border-top: 1px solid #ddd;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.wppWrapper span{
  font-size: 20px;
  color: #222222;
  font-weight: 500;
}

.wpp{
  color: #444;
  line-height: 20px;
  padding: 0px 10px;
}

.textBenefits{
  font-size: 20px;
  color: #222222;
  font-weight: 500;
}

.infosWrapper{
  width: 50%;
}

.infoBox{
  position: sticky !important;
  top: var(--navigation-bar-offset, 80px) !important;
  z-index: 1 !important;
  width: 100% !important;
  display: inline-block !important;
  padding-right: 1px !important;
  margin-left: 8.333333333333332% !important;
}

.infoChatBox{
  border: 1px solid rgb(221, 221, 221);
  border-radius: 12px;
  padding: 24px;
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.priceText{
  font-size: 22px;
  font-weight: 600;
}

.priceBox{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.buttonInitChat{
  border: none;
  background-color: var(--cafofo-color);
  width: 100%;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.reportBox{
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.reportBox-mobile{
  display: none;
}

/* .rsis-container :nth-child(2){
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center center;
}

.rsis-container :nth-child(1){
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center center;
} */

@media (max-width:400px) {
  .options-wrapper{
    flex-direction: column;
  }
}

@media (max-width:767px) {
  .reportBox-mobile{
    padding: 10px 0px 0px 0px;
    border-top: 1px solid #ddd;
    display: flex !important;
  }
  .wppWrapper{
    margin-top: 10px !important;
  }
}

@media (max-width:767px) {
  .ContainerDetail{
    padding: 0px 0px;
  }

  .imgWrapperDetail div:nth-child(1){
    display: flex !important;
    justify-content: center !important;
    margin-top: 0px !important;
    width: 300px !important;
    height: 200px !important;
  }
  .youtube-wrapper{
    height: 200px !important;
  }
  .rsis-container :nth-child(2){
    display: flex !important;
    justify-content: center !important;
    margin-top: 0px !important;
    width: 300px !important;
    height: 200px !important;
  }

  .imgWrapperDetail :nth-child(1) img{
    width: 100% !important;
    height: 30px !important;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50px;
    justify-content: center;
    align-items: center;
  }

  .bottomWrapper{
    display: block !important;
    flex-direction: column !important;
  }

  .leftColumn{
    width: 100% !important;
  }

  .infosWrapper{
    display: none;
  }

  .mobileFooter{
    height: 55px;
    display: flex !important;
    position: fixed;
    bottom: 0;
    padding: 0 10px;
    width: 100%;
    left: 0;
    background-color: var(--cafofo-background-color);
    box-shadow: 0 -2px 4px rgba(0,0,0,0.2);
    align-items: center;
    justify-content: space-between;
  }

  .wppWrapper{
    padding: 0px 0px 40px 0px !important;
  }

  .buttonInitChat{
    height: 35px !important;
    width: 170px !important;
  }
}

@media (min-width:1920px) {
  .ContainerDetail{
    padding: 0px 20%;
  }
}



