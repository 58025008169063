.back-button-forgot{
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 20%;
}

.back-button-forgot svg{
  margin-right: 10px;
}

.form-wrapper-forgot{
  margin-top: 20px;
}

.form-wrapper-forgot label {
  font-weight: 600;
}

.input-forgot{
  border: none;
  padding: 5px;
  margin-bottom: 20px;
}

.form-wrapper-forgot .btn-secondary{
  background-color: var(--cafofo-color);
  border-color: var(--cafofo-color);
}