/* STYLE EDITAR FOTO DE PERFIL*/
.img-profile-edit{
  width: 100px;
  height: 100px;
  align-self: center;
  border-radius: 50%;
  background-size: cover;
}

.img-profile-edit:hover{
  filter: brightness(0.5);
  cursor: pointer;
}

.img-profile-wrapper{
  display: flex;
  justify-content: center;
}

.img-edit-button{
  /* z-index: 15; */
  display: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  width: 100px;
  height: 100px;
}

.img-edit-button:hover{
  /* z-index: 15; */
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  width: 100px;
  height: 100px;
}

/* STYLE SEPARATOR*/
.separator-bar{
  width: 15%;
  border: 1px solid var(--cafofo-color);
  margin-top: 15px;
}

/* STYLE PROFILE BUTTONS WRAPPER*/
.profile-type-select{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.profile-type-button{
  width: 30%;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  color: #444;
  border: 1px solid #ddd;
}

.profile-type-button:hover{
  border: none;
  background-color: var(--cafofo-color);
  color: #fff;
}

.profile-type-button-active{
  width: 30%;
  border: none;
  background-color: var(--cafofo-color);
  padding: 5px;
  border-radius: 5px;
  color: #fff;
}

/* STYLE SAVE PROFILE BUTTON*/
.button-save-profile{
  width: 100%;
  height: 35px;
  margin-top: 20px;
  border: none;
  background-color: #D3ECA7;
  color: #3D3D3D;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.button-save-profile svg{
  margin-right: 5px;
}

.button-save-profile:hover{
  filter: brightness(0.9);
}

/* STYLE BACK BUTTON*/
.back-icon{
  cursor: pointer;
  color: #444;
}

.back-icon svg{
  margin-right: 5px;
}

.img-profile-wrapper input{
  display: none;
}