.conteudo .nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 1rem;
}

.conteudo .nav-tabs .nav-link.active {
    color: #fff;
    background-color: var(--cafofo-color);
    border-color: var(--cafofo-color);
}

.conteudo .nav-tabs .nav-link {
    white-space: nowrap;
    color: var(--cafofo-color-cinza);
    font-size: 14px;
}
.modal-header{
    align-items: center;

}
.modal-title h2{
    font-size: 14px;
    font-weight: 700;
}
@media (min-width:992px) {
    .modal-lg {
        max-width: 1200px;
    }
}

.modal-content{
    max-height: 80vh;
    overflow: auto;
}

textarea{
    resize: none;
}

.inputNew{
    border: 1px solid #ddd !important;
    border-radius: 10px !important;
}
label#thumbnail{
    margin-bottom: 20px;
    border: 1px dashed #ddd;
    background-size: cover;
    cursor: pointer;
    display: flex;
    height: 160px;
    justify-content: center;
    align-items: center;
}
.select{
    height: 44px;
}

label#thumbnail img{
    height: 50px;
    width: 50px;
}

label#thumbnail input{
    display: none;
}

label#thumbnail.has-thumbnail{
    /* border: 0; */
}

label#thumbnail.has-thumbnail img{
    /* display: none; */
}

.modal-body form {
    display: flex;
    flex-direction: column;
}

.modal-body  div.buttonsRegister{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

.modal-body button.btn-full{
    margin-bottom: 10px;
    width: 100%;
}