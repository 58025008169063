.content div.buttonsRegister{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

.content button.btn-full{
    margin-bottom: 10px;
    width: 100%;
}

.btn-login{
  color: #557cf2 !important;
}