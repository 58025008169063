ul.vacancy-list{
    width: 100%;
    list-style: none;
    display: flex;
    grid-gap: 30px;
    margin-bottom: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0px;
}

ul.vacancy-list li{
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

ul.vacancy-list li header{
    width: 100%;
    height: 120px;
    background-size: cover;
    border-radius: 4px;
}

ul.vacancy-list li strong{
    margin-top: 10px;
    font-size: 17px;
    color: #444;
    width: 250px;
}

ul.vacancy-list li span{
    font-size: 14px;
    color: #999;
}

.imgBox {
    width: 300px;
    height: 200px;
    border-radius: 10px;
}

.imgBoxDetail{
    border-radius: 10px;
    width: 800px;
    height: 500px;
}

.rsis-image{
    border-radius: 10px;
}

.ul.vacancy-list li button {
    z-index: 10 !important;
}

.rsis-container :nth-child(2){
    border-radius: 10px;
}

.rsis-container{
    border-radius: 10px;
}

.vacancyName{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1; 
    -webkit-box-orient: vertical;
}

.newVacancyButton{
    display: none;
}
.btnc{
    border: none;
}

.nameGenderWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.search-input{
    border: none;
    border: 1px solid #ddd;
    height: 35px;
    width: 200px;
    padding: 5px;
    border-radius: 5px;
    width: 40%;
    background-color: #fff;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

.search-input-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    width: 100%;
}

.search-input-button{
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
}

.search-input-button:hover{
    color: var(--cafofo-color);
    transition: 0.2s;
}

@media (max-width:767px) {
    .newVacancyButton{
        display: block;
        margin-bottom: 15px;
    }
    .newVacancyButtonFake{
        display: none;
    }
    .search-input{
        width: 60%;
    }
}

@media (max-width:400px) {
    .search-input{
        width: 60%;
    }
}