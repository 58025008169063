.globalChatsWrapper{
  padding: 20px 100px;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chatsWrapper{
  display: flex;
  height: 81vh;
  width: 100%;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, .1);
}

.chatsInitedWrapper{
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, .1);
  width: 370px;
  overflow: auto;
}

.chatsInitedHeader{
  height: 60px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, .1);
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0px 20px;
}

.chatsInitedSearch{
  height: 40px;
  background-color: blueviolet;
  display: flex;
  width: 100%;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
}

.chatBox{
  height: 80px;
  width: 100%;
  display: flex;
  padding: 10px;
}

.chatWrapper:hover{
  cursor: pointer;
  margin-left: -5px;
  transition: 0.3s;
  border-radius: 10px;
}

.chatWrapper{
  display: flex;
  width: 100%;
}

.chatImg{
  height: 100%;
  width: 20%;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, .1);
  border: 1px solid #eee;
  background-color: #444;
}

.chatInfos{
  width: 80%;
  border: 1px solid #eee;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, .1);
  border-left: none;
  margin-left: -7px;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.chatNameVacancy{
  font-size: 16px;
  font-weight: 600;
  color: #444;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1; 
  -webkit-box-orient: vertical;
}

.chatLastMessage{
  font-size: 14px;
  color: #c2c2c2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1; 
  -webkit-box-orient: vertical;
}

.avatarHeaderChat{
  width: 50px;
  height: 50px;
  border-radius: 25px;
  color: #fff;
  background-color: var(--cafofo-color);
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.nameToWelcome{
  font-size: 16px;
  font-weight: 600;
  color: #444;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1; 
  -webkit-box-orient: vertical;
}

.chatsSearch{
  width: 90%;
  border-radius: 5px;
  background-color: #fff;
  border: none;
  padding: 0px 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.chatsSearchButton{
  width: 10%;
  border-radius: 5px;
  background-color: #fff;
  border: none;
  padding: 3.5px 13px;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 14px;
  align-content: center;
  display: flex;
  flex-direction: column;
}

/* .sendName{
  font-weight: 600;
  color: #444;
} */

.menuRegisto {
  margin: -17px -2.75rem 0 -0.7rem;
  /* background-color: #557CF2; */
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  /* position: absolute;
  right: 960px;
  top: 205px; */
  width: 39px;
}

#DropRegistro {
  padding: unset;
  background-color: unset !important;
  border: unset !important;
  /* transform: scale(1.5); */
  height: 22px;
  margin-top: 0.25rem;
}

#DropRegistro::after {
  display: none;
}

.opcaoExcluir{
  color: #FF5959;
}

.tituloExcluir{
  color: grey;
  margin-left: 2rem;
  font-weight: 500;
}

.optionWrapper{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.lastMessageWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.lastMessageHour{
  font-size: 12px;
  color: #9D9D9D;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  margin-right: -7px;
}

.messagesWrapper{
  display: flex;
  flex-direction: column;
  width: 70%;
  overflow: auto;
}

/* MOBILE */

.globalChatsWrapperMobile{
  height: 96vh;
  width: 100%;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 10%);
  position: absolute;
  top: 55px;
  right: 0;
  overflow: hidden;
}

.chatsInitedHeaderMobile{
  height: 7%;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, .1);
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

.chatsInitedBoxMobile{
  height: 93%;
  /* background-color: chocolate; */
}

.chatWrapperMobile{
  width: 100%;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, .1);
  border-radius: 10px;
  display: flex;
}

.chatInfosMobile{
  width: 80%;
  height: 100%;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chatImgMobile{
  height: 100%;
  width: 20%;
  background-size: cover;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.optionsMobile{
  margin-top: 10px;
  margin-left: 10px;
}

.noChatsInited{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.noChatsInited strong {
  padding: 0px 10%
}

.noChatsInited p{
  font-size: 14px;
  padding: 0px 6%;
  color: #c2c2c2;
}

.firstTip{
  display: flex;
  justify-content: center;
  padding: 0px 4%;
}

.firstTip p{
  padding: 0px 2%;
}

.lastTip p{
  padding: 0px 2%;
}

.lastTip{
  display: flex;
  justify-content: center;
  padding: 0px 4%;
}
