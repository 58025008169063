label#thumbnail{
    margin-bottom: 20px;
    border: 1px dashed #ddd;
    background-size: cover;
    cursor: pointer;
    display: flex;
    height: 160px;
    justify-content: center;
    align-items: center;
}
.select{
    height: 44px;
}

label#thumbnail img{
    height: 50px;
    width: 50px;
}

label#thumbnail input{
    /* display: none; */
}

label#thumbnail.has-thumbnail{
    /* border: 0; */
}

label#thumbnail.has-thumbnail img{
    /* display: none; */
}

.containerNew{
    padding: 0px 9%;
}
@media (min-width:1920px) {
.containerNew{
    padding: 0px 20%;
}
}

.inputNew{
    border: 1px solid #ddd !important;
    border-radius: 10px !important;
}

ul.image-container{
    width: 100%;
    list-style: none;
    display: flex;
    grid-gap: 30px;
    margin-bottom: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0px;
}

ul.image-container li{
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100px;
    border-radius: 4px;
}

.delete-button{
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    cursor: pointer;
}

.image-box{
    background-size: cover;
}

.containerNew #whatsapp{
    width: 97%;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.containerNew .checkbox{
    width: 3%;
    border: 1px solid #ddd;
    height: 39px;
    border-radius: 5px;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerNew .checkbox svg{
    font-size: 20px;
}

.checkbox-border{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    padding: 2px;
    border-radius: 5px;
    width: 75%;
    height: 70%;
}

.show-wpp-tip{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0px 13px;
    margin-top: -15px;
}

.show-wpp-tip p {
    margin-bottom: 0;
    color: #DEBA9D;
    font-weight: bold;
    font-size: 13px;
}

.show-wpp-tip svg{
    margin-top: -2px;
}

.input-wpp-wrapper{
    display: flex;
}

@media (max-width: 920px) {
    .containerNew .checkbox{
        width: 5%;
    }
}

@media (max-width: 820px) {
    .containerNew .checkbox{
        width: 6%;
    }
}

@media (max-width: 760px) {
    .containerNew .checkbox{
        width: 6%;
    }
}

@media (max-width: 620px) {
    .containerNew .checkbox{
        width: 15%;
    }
}


@media (max-width: 400px) {
    .containerNew .checkbox{
        width: 15%;
    }
}