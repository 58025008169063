.buttonMyVacancy{
  border: none;
  background-color: var(--cafofo-color);
  width: 200px;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.vacancyNotFound{
  display: flex;
  height: 80vh;
  width: 96vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 20%;
}

.textVacancyNotFound{
  border: 1px solid var(--cafofo--color);
  font-size: 20px;
  color: var(--cafofo-color-cinza)
}

.vacancyNotFoundBorder{
  width: 100%;
  border: 1px solid var(--cafofo--color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width:767px) {
  .buttonMyVacancy{
    height: 35px !important;
    width: 170px !important;
  }
}