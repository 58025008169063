.vacancy-infos{
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.vacancy-img{
  height: 90px;
  width: 90px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
}

.share-tittle{
  font-size: 20px;
  font-weight: 600;
}

ul.share-buttons-wrapper{
  width: 100%;
  list-style: none;
  display: flex;
  grid-gap: 20px;
  margin-top: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0px;
  justify-content: space-between;
}

.share-button{
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 47%;
  display: flex;
  border-radius: 4px;
  font-weight: 600;
}

.share-button button svg{
  margin-right: 20px;
}

.share-button-copy{
  border: none;
  background: transparent;
  font-weight: 600;
}

.vacancy-name{
  margin-bottom: 0px;
}

@media (max-width:400px) {
  .share-button{
    padding: 0px;
    width: 46%;
  }
}