.boxBenefits{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}

.textBenefits{
  margin-left: 5px;
  color: #444;
}

