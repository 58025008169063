body {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  font-size: 14px;
  background-color: var(--cafofo-background-color) !important;
}

.badgeBase {
  color: white;
  font-weight: bold;
  padding: 5px 5px;
  border-radius: 5px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-between;
}

* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root{
  min-height: 100%;
}

input, button{
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
}

.container{
  margin: 80px auto 0 !important; 
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.content{
  width: 100%;
  border-radius: 4px;
  padding: 15px;
  margin-top: 30px;
  overflow: hidden;
}

.content > p{
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 30px;
}

.content form{
  display: flex;
  flex-direction: column;
}

.content form label{
  font-size: 14px;
  color: #444;
  font-weight: bold;
  margin-bottom: 8px;
}

.content form label span{
  font-size: 12px;
  color: #999;
  font-weight: normal;
}

.content form input{
  margin-bottom: 20px;
  /* border: 1px solid #ddd; */
  /* border-radius: 2px; */
  padding: 8px 15px;
  font-size: 16px;
}

.content form textarea{
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 2px;
  height: 80px;
  padding: 0 15px;
  font-size: 16px;
  resize: none
}

.content div.buttons{
  display: flex;
  justify-content: space-between;
}

.content div.buttons a{
  width: 48%;
}

.content button.btn{
  border: 0;
  border-radius: 10px;
  width: 48%;
  height: 42px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.content button.btn-submit{
  background: #f7faff;
  color: #557cf2;
  border: 1px solid #f7faff;
}

.content button.btn-secondary{
  background: #557cf2;
  width: 100%;
  color: #fff;
}

.content button.btn:hover{
  opacity: 0.9;
}

.wrapper {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-between;
}

a{
  text-decoration: none !important;
}


:root {
  /* Cores */
  --cafofo-color: #557cf2;
  --cafofo-color-2: #f19d1f;
  --cafofo-color-secondary: #64B5F6;
  --cafofo-color-cinza: #868aa8;
  --cafofo-color-green: #4CAF50;
  --cafofo-color-red: #f44336;
  --cafofo-color-dark: #353252;
  --cafofo-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --cafofo-b-radius: 5px;
  --cafofo-overflow-width: 6px;
  --cafofo-overflow-height: 6px;
  --cafofo-background-color: #fff;
  /*  */
  --smooth: 200ms ease-in-out;
}

*::-webkit-scrollbar-track {
  background-color: #ddd;
}

*::-webkit-scrollbar {
  width: var(--cafofo-overflow-width);
  height: var(--cafofo-overflow-height);
  background-color: #ddd;
}

body::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--cafofo-color);
}

.form-control .form-control-color {
  padding: .375rem 14rem !important;
}

.form-group .react-datepicker-wrapper {
  width: 100%;
}

.icon-em-construcao {
  cursor: not-allowed;
  color: var(--cafofo-color-cinza);
  opacity: 40%;
}

.buttonNovaOportunidade.dropup {
  background: none;
}

.buttonNovaOportunidade.dropup .dropdown-toggle::after {
  content: none;
}

.buttonNovaOportunidade.dropup .btn {
  background-color: transparent;
}

.buttonNovaOportunidade .btn.btn-primary {
  background-color: var(--cafofo-color);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 30px;
  bottom: 30px;
  padding: 15px;
  font-size: 35px;
  border-radius: 100%;
  cursor: pointer;
}

.inputLabel {
  color: #868aa8 !important;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 400 !important;
}

@media (max-width:767px) {
  .content{
    margin-top: 20px;
  }
}

