.nameToWelcome{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1; 
  -webkit-box-orient: vertical;
}

.nameToWelcomeMenu{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1; 
  -webkit-box-orient: vertical;
}

.menuHeaderWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.avatar{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #fff;
  background-color: var(--cafofo-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuItens{
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 83%;
}

.logoutWrapper{
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: rgba(7, 7, 7, 0.1);
  margin-bottom: 10px;
}

.footerWrapper{
  bottom: 0px;
  flex-direction: row;
}

.textMenu{
  text-decoration: none;
  color: #444;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 5px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  width: 100%;
}

.textMenuFooter{
  text-decoration: none;
  color: #444;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
}

.textMenuSair{
  text-decoration: none;
  color: #444;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 5px;
  cursor: pointer;
  width: 100%;
}

.logoutButton{
  height: 55px;
  padding: 0px 20px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.footerIconsWrapper{
  bottom: 0px;
  flex-direction: row;
  display: flex;
}

.icons{
  margin-right: 20px;
  cursor: pointer;
}