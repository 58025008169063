.cafofo-menu{
  position: fixed;
  width: 100%;
  z-index: 8;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  background-color: var(--cafofo-background-color);
  box-shadow: 0 -1px 10px rgb(0 0 0 / 30%);
  color: #444;
  top: 0;
  right: 0;
  padding: 0px 9%;
}

.newspaper{
  font-size: 22px;
  cursor: pointer;
}

.new_notions{
  position: absolute;
  font-size: 7px;
  right: 10.1%;
}

.new_body{
  max-height: 30vh;
  overflow: auto;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.news_box{
  border: 1px solid #eee;
  border-radius: 5px;

  padding: 3px 5px;
}

.cafofo-menu .logo{
  height: 75px;
  display: flex;
  align-items: center;
}

.cafofo-menu .logo picture {
  display: flex;
  align-items: center;
  height: 100%;
}
.cafofo-menu .online{
  margin-right: 20px;
  border: 1px solid rgb(221, 221, 221) !important;
  padding: 5px 5px 5px 12px !important;
  border-radius: 21px !important;
  cursor: pointer;
}

.cafofo-menu .online:hover{
  box-shadow: 0px 2px 4px rgb(0 0 0 / 18%) !important;
  transition: 0.2s;
}

.cafofo-menu .logo img{
  width: 180px;
  max-width: 100%;
}

.cafofo-menu .right-nav {
  display: flex;
  height: 75px;
  justify-content: center;
  align-items: center;
}

.cafofo-menu .info-nav{
  font-size: 30px;
  height: 75px;
  align-items: center;
  display: inline-flex;
}
.cafofo-menu .info-nav .picture-topo img{
  width: 25px;
}
.cafofo-menu .info-nav .barsMenuMobile{
display: none;
}

.cafofo-menu .info-nav  .picture-topo{
  cursor: pointer;
  width: 30px;
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
}

.cafofo-menu .right-nav .info-nav > div {
  margin-right: 20px;
  display: inline-flex;
}
.cafofo-menu .right-nav .modulo-name {
  font-weight: 700;
  color: #fff;
  font-size: 16px;
  line-height: unset;
  padding: 0;
  margin: 0;
  margin-right: 25px;
}
.cafofo-menu .right-nav .user-name {
  color: #fff;
  font-size: 15px;
  line-height: unset;
  padding: 0;
  margin: 0px 5px;

}
.cafofo-menu .online{
  display: flex !important;
  align-items: center;
}
.cafofo-mobile-navigation{
  display: none;
}
.dadosEmpreendimento{
  font-size: 10px; 
  line-height: 1;
}
@media (max-width:767px) {
  .cafofo-menu .info-nav  .picture-topo{
      margin-right: 13px;
  }
  .cafofo-menu .right-nav{
      margin-left: 5px;
  }
  .cafofo-menu{
      height: 55px;
  }
  .cafofo-menu .logo img{
      width: 130px;
      max-width: 150%;
  }
  .cafofo-menu .onlineFlag{
    display: none;
  }
  .cafofo-menu .info-nav  .picture-topo .onlineFlag{
      display: none;
  }
  .cafofo-menu .info-nav  .picture-topo .offlineFlag{
      display: none;
  }
  .cafofo-mobile-navigation{
      display: block;
  }
  .cafofo-menu .info-nav .barsMenuMobile{
    display: flex;
  }
  .newVacancyTop{
    display: none;
  }
}
@media (min-width:1920px) {
  .cafofo-menu{
    padding: 0px 20%;
  }
}
.cafofo-menu .perfil-dropdown {
  background: none;
  display: flex;
  border: none;
}
.cafofo-menu .perfil-dropdown h1.user-name {
  margin-top: 10px;
}
.cafofo-menu .perfil-dropdown::after {
  content: none;
}

.iconBar{
  font-size: 15px !important;
  margin-right: 10px !important;
  width: 0.875em !important;
  cursor: pointer;
}

.perfil-dropdown.btn-primary:not(:disabled):not(.disabled).active,
.perfil-dropdown.btn-primary:not(:disabled):not(.disabled):active,
.perfil-dropdown.show>.btn-primary.dropdown-toggle,
.perfil-dropdown.show>.btn-primary.dropdown-toggle:focus,
.perfil-dropdown.btn-primary.focus,
.perfil-dropdown.btn-primary:focus{
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.btnNewVacancy{
  border: none;
  background-color: var(--cafofo-background-color);
  color: #222222;
  font-weight: 600 !important;
  padding: 10px;
  margin-right: 10px;
}

.btnNewVacancy:hover{
  background-color: #f5f5f5;
  border-radius: 20px;
  transition: 0.2s;
}

