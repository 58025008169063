.reportButton{
  margin-top: 20px;
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  border: none;
  background-color: var(--cafofo-color);
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}